.about {
  .about-hero {
    background-color: var(--primaryBackground--);
    padding-top: 100px;
    // h1 {
    //   color: var(--primaryColor--);
    // }

    img {
      width: 100%;
      transition: all 0.3s ease-in;
    }
    // img:hover {
    //   transform: scale(1.05);
    // }
    button {
      background: var(--primaryColor--);
      color: white;
border: none;
      transition: all 0.3s ease-in;
    }

    button:hover {
      background-color: white;
      border: 1px solid var(--primaryColor--);
      color: var(--primaryColor--);
      box-shadow:  4px 4px var(--primaryColor--);

      
    }
  }

  .about-sec1 {
    button {
      background-color: var(--primaryColor--);
      color: white;
    }
  }

  .about-ourStory {
    background-color: var(--primaryBackground--);
  }

  .about-founder {
    font-family: public sans;
  }

  .about-sec2 {
    img {
      width: 100%;
    }
    background-color: var(--primaryBackground--);
    .aboutSec2-card {
      border-radius: 35px !important;
    }
  }

  .about-sec3 {
    position: relative;
    padding-top: 180px;
    padding-bottom: 100px;
    .upper-div {
      background-color: var(--primaryBackground--);
      height: 550px;
      position: relative;
      /* z-index: 1; */
    }
    .lower-div {
      border-radius: 10px;
      background-color: var(--primaryColor--);
      color: white;
      position: absolute;
      /* left: 100px; */
      top: -100px;
      padding: 20px;

      /* z-index: 2; */
    }
    .aboutSec3-card {
      border-radius: 15px !important;
      height: 290px;
    }
    .owl-theme .owl-nav {
      position: absolute !important;
      top: 40% !important;
      width: 100% !important;
      transform: translateY(-50%) !important;
    }

    .owl-carousel .owl-nav button.owl-prev {
      position: absolute !important;
      color: white !important;
      display: flex !important;
      justify-content: center !important;
      align-items: end !important;
      font-size: 40px !important;
      background-color: var(--primaryColor--) !important;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      left: 5px !important;
    }

    .owl-carousel .owl-nav.disabled {
      display: block !important;
    }

    .owl-carousel .owl-nav button.owl-next {
      position: absolute !important;
      color: white !important;
      display: flex !important;
      justify-content: center !important;
      align-items: end !important;
      font-size: 40px !important;
      background-color: var(--primaryColor--) !important;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      right: 10px !important;
    }
  }
  .about-sec4 {
    img {
      width: 100%;
    }
  }
  .about-sec5 {
    img {
      width: 100%;
    }
  }

  .about-why {
    margin-top: 150px;
    margin-bottom: 230px;
    background-color: var(--primaryBackground--);
    .card {
      height: 280px;
      border-radius: 20px;
    }
    .infiwhy1 {
      margin-top: -100px;
    }
    .infiwhy4 {
      margin-bottom: -170px;
    }

    .infiwhy3 {
      margin-top: -100px;
      margin-bottom: -30px;
    }
    @media screen and (max-width: 570px) {
      .card {
        margin: 10px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .infiwhy-left {
      margin-bottom: 100px;
    }
  }
  .about-sec5 {
    background-color: var(--primaryBackground--);
    .accordion-button.collapsed::after {
      content: "+";
    }

    .accordion-button:not(.collapsed)::after {
      content: "-";
    }
  }
}
