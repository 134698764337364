@import url("https://fonts.googleapis.com/css2?family=Ramabhadra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
* {
  --primaryColor--: #184363;
  --primaryBackground--: #ebf1f6;
}

.App {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: ramabhadra;
}

p {
  font-family: public sans;
}
