.navbar {
  background-color: var(--primaryBackground--) !important;
  color: white;
  .navbar-brand {
    transition: all ease-in;
  }
  // .navbar-brand:hover {
  //   transform: scale(1.1);
  // }
}

.nav {
  // display: flex;
  // justify-content: space-around;
}
.nav {
  a {
    text-decoration: none;
    color: var(--primaryColor--);
  }
  .nav-item:hover {
    // background-color: var(--primaryColor--);
    // color: white;
    // border-radius: 3px;

    // text-decoration: underline;
    // padding: 3px;
  }

  .nav-link.dropdown-toggle {
    background-color: var(--primaryBackground--);
  }
  .nav-link.dropdown-toggle:hover {
    background-color: var(--primaryBackground--);
  }

  .dropdown-menu {
    background-color: var(--primaryColor--);
    .dropdown-item {
      color: white;
    }
    .dropdown-item:hover {
      color: var(--primaryColor--);
    }
    .dropdown-item:active {
      background-color: var(--primaryBackground--) !important;
    }
  }

  button {
    background: var(--primaryBackground--);
    color: var(--primaryColor--);
    border: 2px solid var(--primaryColor--);
    // box-shadow: 10px 10px 5px black;
    transition: all 0.3s ease-in;
  }

  button:hover {
    background-color: var(--primaryColor--);
    color: var(--primaryBackground--);
  }
}


.underline-animation {
  display: inline-block;
  position: relative;

  &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 3px;
      bottom: 0;
      border-radius: 4px;
      left: 0;
      background-color: #184363;
      transition: transform 0.3s ease-out;
  }

  &:hover {
      &::after {
          transform: scaleX(1);
      }
  }

  &.center-animate {
      &::after {
          transform-origin: bottom center;
      }

      &:hover::after {
          transform-origin: bottom center;
      }
  }
}