.footer {
  background-color: var(--primaryColor--);
  color: white;
  position: relative;
}

.footer a {
  color: white;
  text-decoration: none;
  // &:hover{
  //   text-decoration: underline;
  // }
}

.icon-div {
  border-radius: 50%;
flex-shrink: 0;
width: 50px;
height: 50px;
  border: 1px solid white;
  // display: inline-flex;
  
   margin: 10px;
  transition: all 0.3s ease-in;
   &.facebook{
    padding: 10px 19px;
    &:hover{
      background-color: #fff;
      i{
        color: #316FF6;
      }
    }
   }
   &.instagram{
    padding: 12px 18px;
    &:hover{
      background-color: #fff;
      i{
        color: purple;
      }
    }
   }
   &.linkdin{
    padding: 12px 18px;
    &:hover{
      background-color: #fff;
      i{
        color: #316FF6;
      }
    }
   }
   &.youtube{
    padding: 12px 15px;
    &:hover{
      background-color: #fff;
      i{
        color: red;
      }
    }
   }
}

.scroll-up {
  border: 15px solid white;
  background-color: var(--primaryColor--);
  position: absolute;
  top: -50px;
  right: 0px;
  border-radius: 20px;
}

.scroll-up button {
}


.underline-animation {
  display: inline-block;
  position: relative;

  &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 3px;
      bottom: 0;
      border-radius: 4px;
      left: 0;
      background-color: #fff;
      transition: transform 0.3s ease-out;
  }

  &:hover {
      &::after {
          transform: scaleX(1);
      }
  }

  &.center-animate {
      &::after {
          transform-origin: bottom center;
      }

      &:hover::after {
          transform-origin: bottom center;
      }
  }
}