.infisuite {
  .infisuite-hero {
    padding-top: 100px;
    background-color: var(--primaryBackground--);
    img {
      width: 100%;
      transition: all 0.3s ease-in;
    }
    // img:hover {
    //   transform: scale(1.05);
    // }

    .hero-left {
      margin-left: 100px;
    }
    button {
      background: var(--primaryColor--);
      color: white;

      transition: all 0.3s ease-in;
    }

    button:hover {
      background: var(--primaryBackground--);
      border: 1px solid var(--primaryColor--);
      color: var(--primaryColor--);
      box-shadow: 10px 10px var(--primaryColor--);
    }
  }
  // .infisuite-why {
  //   margin-top: 150px;
  //   margin-bottom: 230px;
  //   background-color: var(--primaryBackground--);
  //   .card {
  //     height: 280px;
  //     border-radius: 20px;
  //   }
  //   .infiwhy1 {
  //     margin-top: -100px;
  //   }
  //   .infiwhy4 {
  //     margin-bottom: -170px;
  //   }

  //   .infiwhy3 {
  //     margin-top: -100px;
  //     margin-bottom: -30px;
  //   }
  //   @media screen and (max-width: 570px) {
  //     .card {
  //       margin: 10px;
  //     }
  //   }
  // }
  // @media screen and (max-width: 1000px) {
  //   .infiwhy-left {
  //     margin-bottom: 100px;
  //   }
  // }
}
