.infisuite-bd {
  .background {
    background-color: var(--primaryBackground--);
    height: 420px;
    border-radius: 20px;
    position: relative;

    .bd-img {
      position: absolute;
      bottom: -100px;
      left: 5%;
    }
  }

  @media screen and (max-width: 1200px) {
    .background {
      .bd-img {
        position: static;
      }
    }
  }
}

.infisuite-sec1 {
  img {
    width: 100%;
  }
  .heading1-div {
    display: inline-block;
    color: var(--primaryColor--);
    background-color: var(--primaryBackground--);
  }

  .scroll-sec1 {
    height: 445px;
    overflow-y: scroll;

    // scrollbar-width: thin;
  }

  .scroll-sec2 {
    height: 445px;
    overflow-y: scroll;
    direction: rtl;
    // scrollbar-width: thin;
  }

  .sec1-right {
    background-color: var(--primaryBackground--);
  }
  .sec1-right:hover {
    background-color: var(--primaryColor--);
    color: white;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: var(--primaryBackground--);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primaryColor--);
  border-radius: 15px;
}
